// ==========[ GENERAL ]==========

* {
	font-feature-settings: "kern" 1;
	font-kerning: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin: 0;
	padding: 0;
}

h1 {
	color: $black;
	text-transform: uppercase;
	font-family: $ff-open-sans;
	font-size: 32px;
	font-weight: bold;
	line-height: 38.4px;
}

h2 {
	color: $orange;
	font-family: $ff-montez;
	font-size: 32px;
	line-height: 40px;
}

h3 {
	color: $black;
	text-transform: uppercase;
	font-size: 24px;
	font-weight: bold;
	line-height: 28.8px;
}

h4 {
	color: $grey-dark;
	text-transform: uppercase;
	font-family: $ff-open-sans;
	font-size: 14px;
	font-weight: bold;
	line-height: 18px;
	letter-spacing: 0.1em;
}

h5 {}

.cms,
p {
	color: $grey;
	font-family: $ff-libre-baskerville;
	font-size: 16px;
	font-weight: normal;
	line-height: 24px;

	a {
		color: $orange;
		text-decoration: underline;

		&:hover {
			color: $brown;
			text-decoration: none;
		}
	}
}

// ==========[ FORMS ]==========

form {
	.form-group {
		label {
			color: $grey;
			font-family: $ff-libre-baskerville;
			font-size: 16px;
			font-weight: bold;
			line-height: 24px;
		}

		.form-control {
			color: $grey-dark;
			border: 1px solid #D6D6D6;
			border-radius: 0;

			&:focus {}
		}
	}
}

.custom-checkbox {
	min-height: 30px;
	padding-left: 30px;

	.custom-control-label {
		padding-left: 10px;

		&:before {
			width: 24px;
			height: 24px;
			margin-top: -5px;
			border: 1px solid #D6D6D6;
			border-radius: 0;
			background-color: transparent;
		}

		&:after {
			width: 24px;
			height: 24px;
			margin-top: -5px;
			background-position: center;
		}
	}

	.custom-control-input {
		&:checked~ {
			.custom-control-label {
				&:before {
					color: $white;
					border: 1px solid $orange;
					background-color: $orange;
				}
			}
		}
	}
}

// ==========[ HEADER ]==========

.navbar {
	padding-top: 35px;
	padding-bottom: 35px;
	background-color: $yellow-light;

	.navbar-brand {
		padding: 0;
		color: $orange;
		font-family: $ff-montez;
		font-size: 60px;
		line-height: 60px;
	}

	.nav-item {
		.nav-link {
			padding-right: 15px;
			padding-left: 15px;
			color: $black;
			text-transform: uppercase;
			font-family: $ff-open-sans;
			font-size: 12px;
			font-weight: bold;
			line-height: 18px;
			letter-spacing: .1em;

			&.nav-social {
				color: $grey;
				font-size: 18px;

				&:hover {
					color: $orange;
				}
			}
		}

		&.active,
		&:hover {
			.nav-link {
				color: $orange;
			}
		}
	}
}

// ==========[ FOOTER ]==========

footer {
	background-color: $black;

	.logo {
		color: $orange;
		font-family: $ff-montez;
		font-size: 40px;
		line-height: 40px;
	}

	h4 {
		color: $brown;
		text-transform: uppercase;
		font-family: $ff-open-sans;
		font-size: 14px;
		font-weight: bold;
		line-height: 18px;
		letter-spacing: 0.1em;
	}

	.cms {
		color: $white;
		font-family: $ff-libre-baskerville;
		font-size: 13px;
		line-height: 19.5px;
	}

	ul {
		margin: 0;
		padding: 0;
		list-style-type: none;

		li a {
			margin-bottom: 10px;
			color: $white;
			text-transform: uppercase;
			font-family: $ff-open-sans;
			font-size: 12px;
			font-weight: bold;
			line-height: 18px;
			letter-spacing: 0.1em;

			&:hover {
				color: $orange;
				text-decoration: none;
			}
		}
	}

	.social {
		color: $white;
		font-size: 21px;

		&:hover {
			color: $orange;
			text-decoration: none;
		}
	}

	.sub-footer {
		display: flex;
		align-items: center;
		justify-content: space-between;

		p {
			color: $brown;
			font-family: $ff-libre-baskerville;
			font-size: 13px;
			font-weight: normal;
			line-height: 19.5px;

			a {
				color: $brown;

				&:hover {
					color: $orange;
					text-decoration: none;
				}
			}
		}
	}
}

// ==========[ KAMERS ]==========

.kamer-item {
	.image {
		@include quart-transition(400ms);
		position: relative;
		width: 100%;
		padding-top: 75%;
		border-radius: 10px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		box-shadow: 0;

		.inner {
			@include quart-transition(400ms);
			display: flex;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			opacity: 0;
			align-items: center;
			flex-direction: column;
			justify-content: center;
			transform: translateY(15px);
		}

		&:hover {
			box-shadow: 0 0 30px $orange;

			.inner {
				opacity: 1;
				transform: translateY(0);
			}
		}
	}
}

.table {
	th {
		padding: 10px 40px;
		color: $white;
		border: 0;
		background-color: #000;
		font-family: $ff-libre-baskerville;
		font-size: 14px;
		font-weight: bold;
		line-height: 21px;
	}

	tr {
		border: 0;

		td {
			padding: 10px 40px;
			color: $grey;
			border: 0;
			font-family: $ff-libre-baskerville;
			font-size: 14px;
			font-weight: normal;
			line-height: 21px;
		}

		&:nth-child(2n) {
			background-color: $yellow-light;
		}

		&:hover {
			background-color: $orange;

			td {
				color: $white;
			}
		}
	}
}

// ==========[ ARRANGEMENTEN ]==========

.arrangement-item {
	position: relative;
	padding: 40px;
	border: 2px solid $orange;
	border-radius: 10px;

	.bottom-button {
		position: absolute;
		bottom: -20px;
		left: 40px;
	}
}

// ==========[ FOTOS ]==========

.filters {
	display: flex;
	align-items: center;
	justify-content: center;

	.filter {
		margin-right: 10px;
		padding: 11px 20px;
		color: $grey;
		border-radius: 10px;
		background-color: $yellow-light;
		font-family: $ff-libre-baskerville;
		font-size: 14px;
		font-weight: bold;
		line-height: 21px;

		&:last-child {
			margin-right: 0;
		}

		&.active,
		&:hover {
			color: $white;
			background-color: $orange;
			text-decoration: none;
		}
	}
}