// ==========[ HELPER CLASSES ]==========

.bg-yellow {
	background-color : $yellow-light;
}

.c-brown {
	color : $brown;
}

.fz-small {
	font-size : 14px !important;
}

.pull-up {
	margin-top : -50px;
}

// ==========[ BUTTONS ]==========

.btn {
	padding        : 9px 18px;
	border-radius  : 999px;
	text-transform : uppercase;
	font-size      : 12px;
	font-weight    : bold;
	letter-spacing : .1em;
}

.btn-primary, .btn-primary:link, .btn-primary:active, .btn-primary:visited, .btn-primary:focus {
	color            : $white;
	border           : 2px solid $orange;
	background-color : $orange;
	box-shadow: none;

	&:hover {
		border           : 2px solid lighten($orange, 6%);
		background-color : lighten($orange, 6%);
	}
}

.btn-secondary, .btn-secondary:link, .btn-secondary:active, .btn-secondary:visited, .btn-secondary:focus {
	color            : $orange;
	border           : 2px solid $orange;
	background-color : transparent;
	box-shadow: none;

	&:hover {
		color            : $white;
		border           : 2px solid lighten($orange, 6%);
		background-color : lighten($orange, 6%);
	}
}

.btn-selection {
	display          : block;
	width            : 100%;
	color            : #a8a8a8;
	border           : 0px solid #D4D4D4;
	border-radius    : 0;
	background-color : transparent;
	text-transform   : none;
	font-family      : $ff-libre-baskerville;
	font-size        : 16px;
	font-weight      : normal;
	line-height      : 24px;
	letter-spacing   : 0;
}

// ==========[ IMAGE BLOCKS ]==========

.full-width-left {
	@include              softshadow;
	position            : absolute;
	top                 : 3rem;
	right               : 0;
	bottom              : 3rem;
	width               : 42.3vw;
	border-radius       : 10px 0 0 10px;
	background-repeat   : no-repeat;
	background-position : center;
	background-size     : cover;

	.button {
		display          : flex;
		position         : absolute;
		top              : 30px;
		right            : 30px;
		width            : 40px;
		height           : 40px;
		color            : $white;
		border-radius    : 999px;
		background-color : $orange;
		align-items      : center;
		justify-content  : center;
	}

	&.pull-bottom {
		bottom : -40px;
	}
}

.full-width-right {
	@include              softshadow;
	position            : absolute;
	top                 : 3rem;
	bottom              : 3rem;
	left                : 0;
	width               : 42.3vw;
	border-radius       : 0 10px 10px 0;
	background-repeat   : no-repeat;
	background-position : center;
	background-size     : cover;

	.button {
		display          : flex;
		position         : absolute;
		top              : 30px;
		right            : 30px;
		width            : 40px;
		height           : 40px;
		color            : $white;
		border-radius    : 999px;
		background-color : $orange;
		align-items      : center;
		justify-content  : center;
	}

	&.pull-bottom {
		bottom : -40px;
	}
}

.image-with-hover {
	@include              quart-transition(400ms);
	display             : block;
	position            : relative;
	width               : 100%;
	padding-top         : 75%;
	border-radius       : 10px;
	background-repeat   : no-repeat;
	background-position : center;
	background-size     : cover;

	.button {
		@include           quart-transition(400ms);
		display          : flex;
		position         : absolute;
		top              : calc(50% - 20px);
		left             : calc(50% - 20px);
		width            : 40px;
		height           : 40px;
		color            : $white;
		border-radius    : 999px;
		background-color : $orange;
		align-items      : center;
		font-size        : 18px;
		justify-content  : center;
		transform        : scale(0);
	}

	&:hover {
		box-shadow : 0 0 30px $orange;
		transform  : scale(1.05);

		.button {
			transform : scale(1);
		}
	}
}

// ==========[ TROEF ]==========

.troef {
	display         : flex;
	align-items     : flex-start;
	justify-content : flex-start;

	i,
	svg {
		margin-top   : 4px;
		margin-right : 10px;
		color        : $orange;
		flex         : 0 0 20px;
	}
}

// ==========[ NAV TABS ]==========

.nav-tabs {
	border : 0;

	.nav-item {
		.nav-link {
			padding          : 11px 20px;
			color            : $grey;
			background-color : transparent;
			font-family      : $ff-libre-baskerville;
			font-size        : 14px;
			font-weight      : bold;
			line-height      : 21px;

			&:hover {
				color  : $orange;
				border : 1px solid transparent;
			}

			&.active {
				color         : $white;
				border        : 2px solid $orange;
				border-radius : 10px 10px 0px 0px;
				background    : $orange;
			}
		}
	}
}

.tab-content {
	margin-top    : 1px;
	padding       : 20px;
	border        : 2px solid $orange;
	border-radius : 0px 10px 10px 10px;
}

// ==========[ SHARING ]==========

.sharing {
	a {
		color     : $black;
		font-size : 21px;

		&:hover {
			color : $orange;
		}
	}
}

// ==========[ BORDER BOX ]==========

.border-box {
	border        : 2px solid $orange;
	border-radius : 10px;
}
