// ==========[ FONTS ]==========

@font-face {
  font-family: 'Montez';
  font-weight: normal;
  src        : url(/dist/assets/fonts/Montez-Regular.otf);
  src        : url(/dist/assets/fonts/Montez-Regular.woff);
  src        : url(/dist/assets/fonts/Montez-Regular.woff2);
}

$ff-libre-baskerville : 'Libre Baskerville',
serif;
$ff-montez : 'Montez',
cursive;
$ff-open-sans : 'Open Sans',
sans-serif;

// ==========[ COLORS ]==========

$white      : #FFFFFF;
$grey-light : #F6F6F6;
$grey       : #A8A6A3;
$grey-dark  : #414042;
$black      : #381908;

$yellow-light : #FFF7E5;
$brown        : #A2745A;
$orange       : #EAA118;
$red          : #BC4646;


// ==========[ MIXINS ]==========

@mixin boxshad() {
  box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
}

@mixin softshadow() {
  box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, .3);
}

@mixin quart-transition($duration) {
  transition: all $duration cubic-bezier(0.770, 0.000, 0.175, 1.000);
}