// Extra small devices (portrait phones, less than 576px)

@media (max-width : 575.98px) {
  .off-canvas-nav {
    right: -100vw;
    width: 100vw;
  }

  .navbar-brand {
    img {
      width: 150px;
    }
  }
}

// Small devices (landscape phones, less than 768px)

@media (max-width : 767.98px) {
  .navbar {
    .navbar-brand {
      font-size  : 36px;
      line-height: 36px;
    }
  }

  .full-width-left {
    height: 350px !important;
  }

  .table {
    th {
      padding: 10px;
    }

    tr {
      td {
        padding: 10px;
      }
    }
  }

  .arrangement-item {
    padding: 20px 20px 40px 20px;

    .bottom-button {
      position : relative;
      left     : 0;
      font-size: 11px;

      i,
      svg {
        display: none;
      }
    }
  }

  .filters {
    flex-direction: column;

    .filter {
      margin-right : 0;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

// Medium devices (tablets, less than 992px)

@media (max-width : 991.98px) {

  .navbar-expand-lg>.container,
  .navbar-expand-lg>.container-fluid {
    padding-right: 15px;
    padding-left : 15px;
  }

  .full-width-left,
  .full-width-right {
    position     : relative;
    top          : auto;
    right        : auto;
    bottom       : -20px;
    width        : 100%;
    height       : 500px;
    border-radius: 0;
    box-shadow   : none;
  }

  footer {
    .sub-footer {
      align-items   : flex-start;
      flex-direction: column;
    }
  }

  .pull-up {
    margin-top: 0;
  }
}

// Large devices (desktops, less than 1200px)

@media (max-width : 1199.98px) {}